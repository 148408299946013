import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { inject } from '@angular/core';
import { RoutePaths } from '@utils/route.utils';
import { combineLatest, filter, map } from 'rxjs';
import { CallStateEnum } from '@models/call-state.model';
import { AuthStore } from '@app/store/auth/auth.store';
import { toObservable } from '@angular/core/rxjs-interop';

export const authGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const authStore = inject(AuthStore);
  const authUser$ = toObservable(authStore.authUser);
  const signInCallState$ = toObservable(authStore.signInCallState);
  const authUserCallState$ = toObservable(authStore.authUserCallState);

  router.createUrlTree([RoutePaths.Core.Home.absolutePath]);
  return combineLatest([authUser$, signInCallState$, authUserCallState$]).pipe(
    filter(([authUser, loginCallState, authUserCallState]) => {
      return (
        loginCallState !== CallStateEnum.Loading &&
        authUserCallState !== CallStateEnum.Loading
      );
    }),
    map(([authUser, _loginCallState, _authUserCallState]) => {
      if (!authUser) {
        router.navigate([RoutePaths.Core.Home.absolutePath]);
        return false;
      }

      return true;
    })
  );
};
