import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot
} from '@angular/router';

import { map, take } from 'rxjs/operators';
import { AuthStore } from '@app/store/auth/auth.store';
import { toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, filter } from 'rxjs';
import { RoutePaths } from '@utils/route.utils';
import { FormConfigsStore } from '@app/store/form-configs/form-configs.store';
import { CallStateEnum } from '@models/call-state.model';

export const agreementGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const authStore = inject(AuthStore);
  const authUser$ = toObservable(authStore.authUser);
  const configs = inject(FormConfigsStore);
  const router = inject(Router);
  const callState$ = toObservable(configs.callState);

  return combineLatest([callState$, authUser$]).pipe(
    filter(
      ([callState, authUser]) =>
        callState === CallStateEnum.Loaded && Boolean(authUser)
    ),
    take(1),
    map(([_, authUser]) => {
      if (
        authUser.aaVersion ===
        configs.profileFormDataConfigs().configs.aaVersion
      ) {
        return router.createUrlTree([RoutePaths.Core.My.absolutePath]);
      }

      return true;
    })
  );
};
