import { Routes } from '@angular/router';
import { META_TITLE_KEY, RoutePaths } from '@utils/route.utils';
import { authGuard } from '@guards/auth.guard';
import { canActivateWhenUnauthenticatedGuard } from '@guards/can-activate-when-unauthenticated.guard';
import { uiKitGuard } from '@guards/ui-kit.guard';
import { agreementGuard } from '@guards/agreement.guard';
import { aaVersionGuard } from '@guards/aa-version.guard';

export const coreShellRoutes: Routes = [
  {
    path: RoutePaths.Core.Home.path,
    canActivate: [canActivateWhenUnauthenticatedGuard],
    loadChildren: () =>
      import('src/app/pages/home/home-shell.routes').then(
        (r) => r.homeShellRoutes
      )
  },
  {
    path: RoutePaths.Core.Auth.path,
    canActivate: [canActivateWhenUnauthenticatedGuard],
    loadChildren: () =>
      import('src/app/pages/auth/auth-shell.routes').then(
        (r) => r.authShellRoutes
      )
  },
  {
    path: RoutePaths.Core.My.path,
    canActivate: [authGuard, aaVersionGuard],
    loadChildren: () =>
      import('src/app/pages/my/my-shell.routes').then((r) => r.myShellRoutes)
  },
  {
    path: RoutePaths.Core.Agreement.path,
    loadChildren: () =>
      import('src/app/pages/agreement/agreement-shell.routes').then(
        (r) => r.agreementShellRoutes
      ),
    canActivate: [authGuard, agreementGuard]
  },
  {
    path: RoutePaths.Core.VerifyEmail.path,
    loadChildren: () =>
      import(
        'src/app/pages/email-change-verify/email-change-verify-shell.routes'
      ).then((r) => r.emailChangeVerifyShellRoutes)
  },
  {
    path: RoutePaths.Core.Verify.path,
    canActivate: [canActivateWhenUnauthenticatedGuard],
    loadChildren: () =>
      import('src/app/pages/verify/verify-shell.routes').then(
        (r) => r.verifyShellRoutes
      )
  },
  {
    path: RoutePaths.Core.RecoverPassword.path,
    canActivate: [canActivateWhenUnauthenticatedGuard],
    loadChildren: () =>
      import(
        'src/app/pages/recover-password/recover-password-shell.routes'
      ).then((r) => r.recoverPasswordShellRoutes)
  },
  {
    path: RoutePaths.Core.Contact.path,
    data: { [META_TITLE_KEY]: 'contact_page_meta_title' },
    loadChildren: () =>
      import('@app/pages/contact/contact-shell.routes').then(
        (r) => r.contactShellRoutes
      )
  },
  {
    path: RoutePaths.Core.Pricing.path,
    data: { [META_TITLE_KEY]: 'pricing_page_meta_title' },
    loadChildren: () =>
      import('@app/pages/pricing/pricing-shell.routes').then(
        (r) => r.pricingShellRoutes
      )
  },
  {
    path: RoutePaths.Core.FAQ.path,
    data: { [META_TITLE_KEY]: 'faq_page_meta_title' },
    loadChildren: () =>
      import('@app/pages/faq/faq-shell.routes').then((r) => r.faqShellRoutes)
  },
  {
    path: RoutePaths.UiKit.path,
    canActivate: [uiKitGuard],
    loadChildren: () =>
      import('@app/ui-kit/ui-kit.routes').then((m) => m.UiKitRoutes)
  },
  { path: '**', redirectTo: RoutePaths.Core.Home.absolutePath }
];
