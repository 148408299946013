import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { environment } from '@env/environment';
import { EnvironmentTargetEnum } from '@models/enviroment.model';
import { inject } from '@angular/core';
import { RoutePaths } from '@utils/route.utils';

export const uiKitGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const router = inject(Router);

  if (environment.target !== EnvironmentTargetEnum.Development) {
    router.navigate([RoutePaths.Core.Home.absolutePath]);
  }

  return environment.target === EnvironmentTargetEnum.Development;
};
