import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot
} from '@angular/router';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthStore } from '@app/store/auth/auth.store';
import { toObservable } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';

export const translateGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const translateService = inject(TranslateService);

  return translateService
    .getTranslation(translateService.currentLang)
    .pipe(map((res) => !!res));
};
