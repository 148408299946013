import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  Routes,
  withInMemoryScrolling
} from '@angular/router';
import { coreShellRoutes } from '@app/core/core-shell.routes';
import { RoutePaths } from '@shared/utils/route.utils';
import { translateGuard } from '@guards/translate.guard';

export const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled'
};

export const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const routes: Routes = [
  {
    path: RoutePaths.Inject.path,
    canActivate: [translateGuard],
    loadChildren: () =>
      import('src/app/pages/impersonation/impersonation-shell.routes').then(
        (r) => r.impersonationShellRoutes
      )
  },
  {
    path: '',
    canActivate: [translateGuard],
    loadComponent: () =>
      import('./core/core.component').then((c) => c.CoreComponent),
    children: [...coreShellRoutes]
  },
  { path: '**', redirectTo: RoutePaths.Core.Home.absolutePath }
];
